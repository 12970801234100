import * as Types from '../../graphql/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserSelectInputQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserSelectInputQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<Types.User, 'id' | 'name'>
  )> }
);


export const UserSelectInputDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UserSelectInput"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"users"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useUserSelectInputQuery__
 *
 * To run a query within a React component, call `useUserSelectInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSelectInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSelectInputQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSelectInputQuery(baseOptions?: Apollo.QueryHookOptions<UserSelectInputQuery, UserSelectInputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSelectInputQuery, UserSelectInputQueryVariables>(UserSelectInputDocument, options);
      }
export function useUserSelectInputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSelectInputQuery, UserSelectInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSelectInputQuery, UserSelectInputQueryVariables>(UserSelectInputDocument, options);
        }
export function useUserSelectInputSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserSelectInputQuery, UserSelectInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserSelectInputQuery, UserSelectInputQueryVariables>(UserSelectInputDocument, options);
        }
export type UserSelectInputQueryHookResult = ReturnType<typeof useUserSelectInputQuery>;
export type UserSelectInputLazyQueryHookResult = ReturnType<typeof useUserSelectInputLazyQuery>;
export type UserSelectInputSuspenseQueryHookResult = ReturnType<typeof useUserSelectInputSuspenseQuery>;
export type UserSelectInputQueryResult = Apollo.QueryResult<UserSelectInputQuery, UserSelectInputQueryVariables>;